import React, { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./UserProfile.css";
import {formatDate, capitalizeFirstLetter} from "../../utils/utils.js";

const UserProfile = ({ isShown, onClose, handleSignOut }) => {
  const currentUser = useContext(CurrentUserContext);
  const {login, name, family, subscription} = currentUser;
  const {start, end } = subscription;

  return (
    <div
      className={`user-profile ${isShown ? "user-profile_visible" : ""}`}
      onClick={onClose}
    >
      <div className="user-profile__avatar">{name.charAt(0).toUpperCase() + family.charAt(0).toUpperCase()}</div>
      <div className="user-profile__bio">
        <div className="user-profile__name">{capitalizeFirstLetter(name) + ' ' + capitalizeFirstLetter(family)}</div>
        <span className="user-profile__login">{capitalizeFirstLetter(login)}</span>
      </div>
      <div className="user-profile__subscription">Access to {formatDate(end[end.length - 1])}</div>
      {/* Добавляем div для выхода со стилями BEM */}
      <div className="user-profile__logout" onClick={handleSignOut}>Sign Out</div>
    </div>
  );
};

export default UserProfile;
