import "./Register.css";
import { Link } from "react-router-dom";

import { useState, useEffect } from "react";
import useFormWithValidation from "../../hooks/useFormWithValidation.jsx";

export default function Register({ handleRegister, serverError }) {
  const { values, handleChange, resetForm, errors, isValid } =
    useFormWithValidation();

  function handleSubmit(e) {
    e.preventDefault();
    handleRegister(values);
  }

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <main className="register">
      <form className="register__form" name="register" onSubmit={handleSubmit}>
        <h1 className="register__title">Welcome!</h1>
        <div className="register__labels-container">
          <label className="register__label">
            <span className="register__label-text">First name</span>
            <input
              name="name"
              className={`register__input ${
                errors.name ? "register__input_error" : ""
              }`}
              type="text"
              autoComplete="username"
              required
              minLength="2"
              maxLength="30"
              pattern="^[A-Za-zА-Яа-яЁё /s -]+$"
              value={values.name || ""}
              onChange={handleChange}
            />
            <span className="register__error">{errors.name || ""}</span>
          </label>
          <label className="register__label">
            <span className="register__label-text">Last Name</span>
            <input
              name="family"
              className={`register__input ${
                errors.family ? "register__input_error" : ""
              }`}
              type="text"
              autoComplete="family-name"
              required
              minLength="2"
              maxLength="30"
              pattern="^[A-Za-zА-Яа-яЁё\s-]+$"
              value={values.family || ""}
              onChange={handleChange}
            />
            <span className="register__error">{errors.family || ""}</span>
          </label>
          <label className="register__label">
            <span className="register__label-text">Login</span>
            <input
              name="login"
              className={`register__input ${
                errors.login ? "register__input_error" : ""
              }`}
              type="text"
              autoComplete="username"
              required
              minLength="2"
              maxLength="30"
              pattern="^[A-Za-zА-Яа-яЁё\s-]+$"
              value={values.login || ""}
              onChange={handleChange}
            />
            <span className="register__error">{errors.login || ""}</span>
          </label>
          <label className="register__label">
            <span className="register__label-text">E-mail</span>
            <input
              name="email"
              className={`register__input ${
                errors.email ? "register__input_error" : ""
              }`}
              type="email"
              autoComplete="email"
              required
              value={values.email || ""}
              onChange={handleChange}
            />
            <span className="register__error">{errors.email || ""}</span>
          </label>
          <label className="register__label">
            <span className="register__label-text">Password</span>
            <input
              name="password"
              className={`register__input ${
                errors.password ? "register__input_error" : ""
              }`}
              type="password"
              autoComplete="current-password"
              required
              value={values.password || ""}
              onChange={handleChange}
            />
            <span className="register__error">{errors.password || ""}</span>
          </label>
        </div>
        {serverError && (
          <span className="register__error register__error-message">
            {serverError}
          </span>
        )}
        <button
          type="submit"
          className={`register__button ${
            isValid ? "" : "register__button_disabled"
          }`}
          disabled={!isValid}
        >
          Register
        </button>
        <span className="register__support">
          Already registered?&nbsp;
          <Link to="/signin" className="register__link">
            Enter
          </Link>
        </span>
      </form>
    </main>
  );
}
