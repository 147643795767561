import React, { useState } from "react";
import useVisible from "../../hooks/useVisible.jsx";
import "./RenameGroup.css";

function RenameGroup({selectedTag, buttonTag, generateButtonTags}) {
  const [isVisible, setIsVisible] = useVisible("RenameGroupVisibility");
  const [suffixRename, setSuffixRename] = useVisible("suffixRename");
  const [prefixRename, setPrefixRename] = useVisible("prefixRename");
  const [countRename, setCountRename] = useVisible("countRename");

  const [prefix, setPrefix] = useState("");
  const [text, setText] = useState("");
  const [suffix, setSuffix] = useState("");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ExtenstionData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-light fa-input-text"></i>
        </div>
        <div className="button-card__title">Rename</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="prefixRename"
              checked={prefixRename}
              onChange={() => {
                setPrefixRename(!prefixRename);
                if (!prefixRename === false && !suffixRename) {
                  setCountRename(false);
                }else{
                  setCountRename(true);
                }
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="prefixRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Prefix</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="suffixRename"
              checked={suffixRename}
              onChange={() => {
                setSuffixRename(!suffixRename);
                if (!suffixRename === false && !prefixRename) {
                  setCountRename(false);
                }else{
                  setCountRename(true);
                }
              }}
              className="hidden-checkbox"
            />
            <label htmlFor="suffixRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Suffix</span>
            </label>
          </div>
          <div className={`render-group__checkbox ${!prefixRename && !suffixRename ? "disabled" : ""}`}>
            <input
              type="checkbox"
              id="countRename"
              checked={countRename}
              onChange={() => setCountRename(!countRename)}
              className="hidden-checkbox"
            />
            <label htmlFor="countRename">
              <span className="custom-checkbox"></span>
              <span className="label-text">Count</span>
            </label>
          </div>
        </div>

        <div className="rename__input-group">
          <input
            className={`rename__input rename__input-short ${!prefixRename ? "disabled" : ""}`}
            type="text"
            value={prefix}
            onChange={(e) => setPrefix(e.target.value)}
            disabled={!prefixRename}
            placeholder="Prefix"
          />
          <input
            className="rename__input"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="New Name"
          />
          <input
            className={`rename__input rename__input-short ${!suffixRename ? "disabled" : ""}`}
            type="text"
            value={suffix}
            onChange={(e) => setSuffix(e.target.value)}
            disabled={!suffixRename}
            placeholder="Suffix"
          />
        </div>

        <div className="render-group__tools-grid">
          <div
            onClick={() =>
              handleClick(
                "ae2Rename",
                JSON.stringify({
                  prefix: {enabled: prefixRename, text: prefix},
                  suffix: {enabled: suffixRename, text: suffix},
                  text: text,
                  count: countRename,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-image"></i>
            <span className="render-group__button-text">Comp</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "ae2RenameFolder",
                JSON.stringify({
                  prefix: {enabled: prefixRename, text: prefix},
                  suffix: {enabled: suffixRename, text: suffix},
                  text: text,
                  count: countRename,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-regular fa-folder"></i>
            <span className="render-group__button-text">Folder</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "ae2RenameLayers",
                JSON.stringify({
                  prefix: {enabled: prefixRename, text: prefix},
                  suffix: {enabled: suffixRename, text: suffix},
                  text: text,
                  count: countRename,
                })
              )
            }
            className="render-group__button"
          >
            <i class="fa-light fa-layer-group"></i>
            <span className="render-group__button-text">Layer</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default RenameGroup;
