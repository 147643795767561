import React, { useContext } from "react";
import CurrentUserContext from "../../contexts/CurrentUserContext";
import "./SubscriptionCheck.css";

function SubscriptionCheck({ handleSignOut }) {
  const currentUser = useContext(CurrentUserContext);

  const endDates = currentUser.subscription.end;
  if (endDates && endDates.length > 0) {
    const lastEndDate = new Date(endDates[endDates.length - 1]);
    const now = new Date();

    if (now > lastEndDate) {
      const day = lastEndDate.getDate();
      const month = lastEndDate.getMonth() + 1;
      const year = lastEndDate.getFullYear();
      const time = `${lastEndDate.getHours()}:${lastEndDate.getMinutes()}`;

      return (
        <div className="subscription-check" onClick={() => window.location.reload()}>
          <div className="subscription-check__message">
            Your subscription has expired on {day}/{month}/{year} at {time}
          </div>
          <div className="user-profile__logout" onClick={handleSignOut}>Sign Out</div>
        </div>
      );
    }
  }

  if (!currentUser.subscription || currentUser.subscription.status === false) { // поменял местами
    return (
      <div className="subscription-check" onClick={() => window.location.reload()}>
        <div className="subscription-check__message">Subscription is not active</div>
        <div className="user-profile__logout" onClick={handleSignOut}>Sign Out</div>
      </div>
    );
  }

  return null;
}

export default SubscriptionCheck;
