import './Footer.css';

const Footer = () => {
  const handleReload = () => {
    window.location.reload();
  };

  return (
    <div className="footer">
      <div className="footer__button update-button" onClick={handleReload}>
        <i className="fa-regular fa-rotate"></i>
      </div>
    </div>
  );
};

export default Footer;

