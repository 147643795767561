import { v4 as uuidv4 } from 'uuid';

export let textTemplate = {
  resolution: 1,
  fps: 30,
  duration: 15,
  capsName: false,
  items: [
    {
      id: uuidv4(),
      type: 'folder',
      name: '01. Edit Comp',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Color',
          include: [
            {
              id: uuidv4(),
              type: 'comp',
              name: 'Color Control',
              include: false,
            },
          ],
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Text',
          include: false,
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '02. Final Comp',
      include: false,
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '03. Others',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Elements',
          include: false,
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: 'Delete',
      include: false,
    },
  ],
};

export let transitionTemplate = {
  resolution: 1,
  fps: 30,
  duration: 15,
  capsName: false,
  items: [
    {
      id: uuidv4(),
      type: 'folder',
      name: '01. Edit Comp',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Transitions Shots',
          include: [
            {
              id: uuidv4(),
              type: 'folder',
              name: 'Transition 01',
              include: [
                {
                  id: uuidv4(),
                  type: 'comp',
                  name: 'TR1 Shot 01',
                  include: false,
                },
                {
                  id: uuidv4(),
                  type: 'comp',
                  name: 'TR1 Shot 02',
                  include: false,
                },
              ],
            },
          ],
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '02. Final Comp',
      include: false,
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '03. Others',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Elements',
          include: false,
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: 'Delete',
      include: false,
    },
  ],
};

export let slideshowTemplate = {
  resolution: 1,
  fps: 30,
  duration: 15,
  capsName: false,
  items: [
    {
      id: uuidv4(),
      type: 'folder',
      name: '01. Edit Comp',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Color',
          include: [
            {
              id: uuidv4(),
              type: 'comp',
              name: 'Color Control',
              include: false,
            },
          ],
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Image',
          include: false,
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Text',
          include: false,
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '02. Final Comp',
      include: false,
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: '03. Others',
      include: [
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Elements',
          include: false,
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'pMedia',
          include: false,
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'pText',
          include: false,
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Scene',
          include: false,
        },
        {
          id: uuidv4(),
          type: 'folder',
          name: 'Transition',
          include: false,
        },
      ],
    },
    {
      id: uuidv4(),
      type: 'folder',
      name: 'Delete',
      include: false,
    },
  ],
};
