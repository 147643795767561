import React, { useState, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";
import "./ButtonList.css";
import ButtonCard from "../ButtonCard/ButtonCard.jsx";

const ButtonList = ({ buttons, selectedTag }) => {
  const [filteredButtons, setFilteredButtons] = useState([]);

  useEffect(() => {
    const localStorageKey = `buttonOrder`;
    const savedButtonIdsOrder = JSON.parse(localStorage.getItem(localStorageKey)) || [];

    // Сначала упорядочиваем кнопки на основе сохраненного порядка
    const orderedExistingButtons = savedButtonIdsOrder.map(savedId =>
      buttons.find(btn => btn._id === savedId)
    ).filter(Boolean); // Фильтр Boolean убирает возможные null значения

    // Затем добавляем кнопки, которые еще не были сохранены, в конец списка
    const mergedButtons = [
      ...orderedExistingButtons,
      ...buttons.filter(
        (btn) => !orderedExistingButtons.some((savedBtn) => savedBtn._id === btn._id)
      ),
    ];

    setFilteredButtons(mergedButtons);
  }, [buttons]);

  const moveButton = (_id, atIndex) => {
    const { button, index } = findButton(_id);
    const updatedButtons = update(filteredButtons, {
      $splice: [
        [index, 1],
        [atIndex, 0, button],
      ],
    });

    setFilteredButtons(updatedButtons);

    const localStorageKey = `buttonOrder`;
    localStorage.setItem(localStorageKey, JSON.stringify(updatedButtons.map(button => button._id))); // Сохраняем только идентификаторы
  };

  const findButton = (_id) => {
    const button = filteredButtons.find((b) => b._id === _id);
    return {
      button,
      index: filteredButtons.indexOf(button),
    };
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <section className="buttons-card">
        {filteredButtons.length ? (
          <ul className="buttons-card__list">
            {filteredButtons.map((data) => (
              <ButtonCard
                key={data._id}
                button={data}
                moveButton={moveButton}
                findButton={findButton}
                selectedTag={selectedTag}
              />
            ))}
          </ul>
        ) : null}
      </section>
    </DndProvider>
  );
};

export default ButtonList;
