import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";

function ExportImage({
  onPopupStateChange,
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("ExportImageVisibility");
  const [format, setFormat] = useState(
    localStorage.getItem("exportFormat") || "jpeg"
  ); // Read from localStorage

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  useEffect(() => {
    localStorage.setItem("exportFormat", format); // Save to localStorage
  }, [format]);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/psData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  const handleFormatChange = (event) => {
    setFormat(event.target.value);
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i className="fa-regular fa-image"></i>
        </div>
        <div className="button-card__title">Export Image</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__dropdown">
            <span className="graphics__dropdown-text">Export Format</span>
            <select id="format" value={format} onChange={handleFormatChange}>
              <option value="jpeg">JPEG</option>
              <option value="png">PNG</option>
            </select>
          </div>
        </div>
        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "exportImage",
                JSON.stringify({ type: "document", format: format })
              )
            }
            className="resolution-tools__button"
          >
            <i className="fa-light fa-file"></i>
            <span>Export Document</span>
          </div>
          <div
            onClick={() =>
              handleClick(
                "exportImage",
                JSON.stringify({ type: "artboard", format: format })
              )
            }
            className="resolution-tools__button"
          >
            <i className="fa-sharp fa-light fa-grid-2"></i>
            <span>Export Artboards</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ExportImage;
