import React from 'react';
import "./Tags.css";

function Tags({ currentTag, onTagChange }) {

  const handleTagClick = (color) => {
    if (currentTag === color) {
      onTagChange(null);
    } else {
      onTagChange(color);
    }
  };

  return (
    <div className="tags">
      {['#EB3A2D', '#AFEB2D', '#2D8CEB'].map(color => (
        <div
          key={color}
          className={`tag ${currentTag === color ? 'active' : ''}`}
          onClick={() => handleTagClick(color)}
          style={{ backgroundColor: color }}
        ></div>
      ))}
    </div>
  );
}

export default Tags;
