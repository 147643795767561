import React, { useState, useEffect } from "react";
import useVisible from "../../hooks/useVisible.jsx";

function ArtboardsFromDocuments({
  onPopupStateChange,
  selectedTag,
  buttonTag,
  generateButtonTags,
}) {
  const [isVisible, setIsVisible] = useVisible("ArtboardsFromDocumentsVisibility");
  const [artBoardColor, setArtboardColor] = useState(() => {
    return localStorage.getItem("ArtboardsFromDocumentsArtboadColor") || "transparent";
  });

  const backgrounds = {
    white: {
      color: "FFFFFF",
      value: 1,
    },
    black: {
      color: "000000",
      value: 2,
    },
    transparent: {
      color: "",
      value: 3,
    },
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const Dropdown = ({ id, name, labelText, selectedValue, onChange }) => {
    return (
      <div className="render-group__dropdown">
        <span className="graphics__dropdown-text">
          <span
            style={{
              display: "inline-block",
              width: "12px",
              height: "12px",
              backgroundColor: backgrounds[selectedValue]?.color
                ? `#${backgrounds[selectedValue].color}`
                : "transparent",
              outline: backgrounds[selectedValue]?.color
                ? "none"
                : "1px solid #c5c5c5",
              boxSizing: "border-box", // это чтобы outline был внутри элемента
              marginRight: "8px",
            }}
          ></span>
          {labelText}
        </span>
        <select id={id} name={name} value={selectedValue} onChange={onChange}>
          {Object.keys(backgrounds).map((key) => (
            <option key={key} value={key}>
              {capitalizeFirstLetter(key)}
            </option>
          ))}
        </select>
      </div>
    );
  };

  useEffect(() => {
    localStorage.setItem("ArtboardsFromDocumentsArtboadColor", artBoardColor);
  }, [artBoardColor]);

  const handleMediaChange = (event) => {
    setArtboardColor(event.target.value);
  };

  useEffect(() => {
    if (onPopupStateChange) {
      onPopupStateChange(isVisible);
    }
  }, [isVisible]);

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/psData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {});
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-file-import"></i>
        </div>
        <div className="button-card__title">Artboards From Documents</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox-group no-wrap">
            <Dropdown
              id="artBoardColor"
              name="artBoardColor"
              labelText="Artboard Background Color"
              selectedValue={artBoardColor}
              onChange={handleMediaChange}
            />
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "artboardsFromDocuments",
                JSON.stringify({
                  artboardColor: backgrounds[artBoardColor].value,
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-light fa-grid-2"></i>
            <span>Import</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default ArtboardsFromDocuments;
