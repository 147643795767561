import useVisible from "../../hooks/useVisible.jsx";
import "./VideoBolt.css";

function VideoBolt({ selectedTag, buttonTag, generateButtonTags, closeComps }) {
  const [isVisible, setIsVisible] = useVisible("VideoBoltVisibility");
  const [vbPointText, vbSetPointText] = useVisible("vbPointText");
  const [vbOpenComp, vbSetOpenComp] = useVisible("vbOpenComp");

  const handleClick = (script, argument) => {
    window.jsx.evalFile("./Harchenko/AE2 Extension/ExtenstionData.jsx");
    window.jsx.evalScript(`run(${script}(${argument}));`, function (result) {
    });
  };

  if (selectedTag && selectedTag !== buttonTag) {
    return null;
  }

  return (
    <>
      <li
        onClick={() => setIsVisible(!isVisible)}
        className={`button-card resolution-toggle ${isVisible ? "active" : ""}`}
      >
        <div className="button-card__icon">
          <i class="fa-regular fa-square-v"></i>
          <div />
        </div>
        <div className="button-card__title">Videobolt Tools</div>
        {generateButtonTags()}
      </li>
      <div
        className={`render-group ${isVisible ? "" : "render-group--hidden"}`}
      >
        <div className="render-group__checkbox-group">
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="vbPointText"
              checked={vbPointText}
              onChange={() => vbSetPointText(!vbPointText)}
              className="hidden-checkbox"
            />
            <label htmlFor="vbPointText">
              <span className="custom-checkbox"></span>
              <span className="label-text">Convert Point Text</span>
            </label>
          </div>
          <div className="render-group__checkbox">
            <input
              type="checkbox"
              id="vbOpenComp"
              checked={vbOpenComp}
              onChange={() => vbSetOpenComp(!vbOpenComp)}
              className="hidden-checkbox"
            />
            <label htmlFor="vbOpenComp">
              <span className="custom-checkbox"></span>
              <span className="label-text">Open Comp</span>
            </label>
          </div>
        </div>

        <div className="videobolt-tools">
          <div
            onClick={() =>
              handleClick(
                "vbGlobalFitAndAlign",
                JSON.stringify({
                  pointText: vbPointText,
                  openComp: vbOpenComp
                })
              )
            }
            className="resolution-tools__button"
          >
            <i class="fa-regular fa-text-size"></i>
            <span>Fit and Align</span>
          </div>

          <div
            onClick={() => handleClick("vbCleanText", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-solid fa-text-slash"></i>
            <span>Text Clean</span>
          </div>
          <div
            onClick={() => handleClick("vbCopyEffect", false)}
            className="resolution-tools__button"
          >
            <i class="fa-sharp fa-solid fa-copy"></i>
            <span>Copy Effect</span>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoBolt;
